.taskContainer {
    background-color: rgb(20, 20, 58);
    margin-block:20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius:10px;
    position: relative;
    border:2px solid rgb(55, 150, 240);
}
.textGrp {
    flex:1 1 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.textGrp > div {
    background-color: rgb(9, 9, 35);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding:0.25rem;
    border-radius:15px;
}
.styledText {
    margin:10px;
    color: rgb(240,240,240);
}
.btnGrp {
    flex:1 1 30%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}
.neonButton {
    display: inline-block;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    color: rgb(15, 170, 236);
    border: rgb(15,170,236) 0.125em solid;
    padding: 0.25em 1em;
    border-radius: 0.25em;
    text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em currentColor;
    box-shadow: inset 0 0 0.5em 0 rgb(15,170,236);
    position: relative;
    background-color: inherit;
    font-size:1.2rem;
}
.editForm input{
    background-color: black;
    color: white;
    border: none;
    padding: 15px;
    border-radius: 15px;
    margin-inline:20px;
}
.confirmBtn {
    border:2px solid rgb(255, 72, 255);
    color:rgb(255,72,255);
    background-color: inherit;
    padding:12px;
    border-radius:15px;
}
span {
  position: absolute;
  border-radius: 100vmax;
}
.top {
  top: 0;
  left: 0;
  width: 0;
  height: 5px;
  background: linear-gradient(90deg,
      transparent 50%,
      rgba(255, 49, 49, 0.5),
      rgb(255, 49, 49));
}

.bottom {
  right: 0;
  bottom: 0;
  height: 5px;
  background: linear-gradient(90deg,
      rgb(57, 255, 20),
      rgba(57, 255, 20, 0.5),
      transparent 50%);
}

.right {
  top: 0;
  right: 0;
  width: 5px;
  height: 0;
  background: linear-gradient(180deg,
      transparent 30%,
      rgba(0, 255, 255, 0.5),
      rgb(0, 255, 255));
}

.left {
  left: 0;
  bottom: 0;
  width: 5px;
  height: 0;
  background: linear-gradient(180deg,
      rgb(255, 255, 113),
      rgba(255, 255, 113, 0.5),
      transparent 70%);
}

.top {
  animation: animateTop 3s ease-in-out infinite;
}

.bottom {
  animation: animateBottom 3s ease-in-out infinite;
}

.right {
  animation: animateRight 3s linear infinite;
}

.left {
  animation: animateLeft 3s ease-in-out infinite;
}

@keyframes animateTop {
  25% {
    width: 100%;
    opacity: 1;
  }

  30%,
  100% {
    opacity: 0;
  }
}

@keyframes animateBottom {

  0%,
  50% {
    opacity: 0;
    width: 0;
  }

  75% {
    opacity: 1;
    width: 100%;
  }

  76%,
  100% {
    opacity: 0;
  }
}

@keyframes animateRight {

  0%,
  25% {
    opacity: 0;
    height: 0;
  }

  50% {
    opacity: 1;
    height: 100%;
  }

  55%,
  100% {
    height: 100%;
    opacity: 0;
  }
}

@keyframes animateLeft {

  0%,
  75% {
    opacity: 0;
    bottom: 0;
    height: 0;
  }

  100% {
    opacity: 1;
    height: 100%;
  }
}
@media ( width < 800px){
    .taskContainer {
        flex-direction: column;
    }
    .btnGrp{
      justify-content: space-around;
      width: 100%;
    }
}

