@import url('https://fonts.googleapis.com/css?family=Work+Sans:300,600');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital@1&display=swap');

/* navigation styles start here */
.navBarBrand{
    color:white;
    text-decoration:none;
    font-size:2.5rem;
    font-family: "Montserrat";
    font-weight: bolder;
    margin-inline:20px;
}
.navBarBrand:hover{
    color:white;
    cursor: pointer;
}
.header {
    background-color: #121d39;
    text-align: center;
    width: 100%;
    margin: 0px;
    position: relative;
    border:1px solid #121d39
}

.navToggle {
    position: absolute !important;
    top: -9999px !important;
    left: -9999px !important;
    z-index: 999999999;
}

.navToggle:focus ~ .navToggleLabel {
    outline: 3px solid rgba(lightblue, .75);
    z-index: 9999;
}

.navToggleLabel {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 1em;
    height: 100%;
    display: flex;
    align-items: center;
    width: 100px;
    z-index: 9999;
}

.navToggleLabel span,
.navToggleLabel span::before,
.navToggleLabel span::after {
    display: block;
    background: rgb(86, 14, 255);
    height: 2px;
    width: 2em;
    border-radius: 2px;
    position: relative;
}

.navToggleLabel span::before,
.navToggleLabel span::after {
    content: '';
    position: absolute;
}

.navToggleLabel span::before {
    bottom: 7px;
}

.navToggleLabel span::after {
    top: 7px;
}

nav {
    position: absolute;
    text-align: left;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #121d39;
    transform: scale(1, 0);
    transform-origin: top;
    transition: transform 400ms ease-in-out;
}

nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

nav li {
    margin-bottom: 1em;
    margin-left: 1em;
    display: flex;
    align-items: center ;
}

.link{
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
    text-transform: uppercase;
    opacity: 0;
    transition: opacity 150ms ease-in-out;
    color:white;
    text-decoration: none;
    font-family: "Montserrat";
}

/* nav a:hover { */
    /* color: rgb(69, 48, 255); */
/* } */

.navToggle:checked~nav {
    transform: scale(1, 1);
    z-index: 9999;
}

.navToggle:checked~nav a {
    opacity: 1;
    z-index: 999;
    transition: opacity 250ms ease-in-out 250ms;
}
.linkButton {
    text-decoration: none;
    color:white;
    font-family: 'Montserrat';
}
.neonButton {
    /* display: inline-block; */
    cursor: pointer;
    text-decoration: none;
    color: rgb(15, 170, 236);
    border: rgb(15,170,236) 0.125em solid;
    padding: 0.25em 1em;
    border-radius: 0.25em;
    text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em currentColor;
    box-shadow: inset 0 0 0.5em 0 rgb(15,170,236),0 0 0.5em 0 rgb(15,170,236);
    position: relative;
    background-color: inherit;
    font-size:1.2rem;
}

@media screen and (min-width: 800px) {
    .navToggleLabel {
        display: none;
    }

    .header {
        /* display: grid; */
        /* grid-template-columns: 1fr auto minmax(600px, 3fr) 1fr; */
        display: flex;;
        align-items: center;
        justify-content: space-around;
        margin:0px;
        position: relative;
    }

    .logo {
        grid-column: 2 / 3;
    }

    nav {

        /* the following lines are not from my video, but add Edge support */
        position: relative;
        text-align: left;
        transition: none;
        transform: scale(1, 1);
        background: none;
        top: initial;
        left: initial;
        /* end Edge support stuff */
        grid-column: 3 / 4;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    nav ul {
        display: flex;
    }

    nav li {
        margin-left: 3em;
        margin-bottom: 0;
    }

    .link {
        opacity: 1;
        position: relative;
    }

    .link::before {
        content: '';
        display: block;
        height: 5px;
        background: rgb(0, 94, 255);
        position: absolute;
        top: -.75em;
        left: 0;
        right: 0;
        transform: scale(0, 1);
        transition: transform ease-in-out 250ms;
    }

    .link:hover::before {
        transform: scale(1, 1);
    }
}