.bodyWrapper {
    background-color: #0F1527;
    width: 100%;
    height: 100vh;
    overflow-y: hidden;
    margin:0px;
    padding-left:2rem;
}
.heroTitle {
    font-size: 4rem;
    font-family: "Montserrat";
    color: rgb(193, 204, 241);
    color:white;
}
.content{
    color: rgb(193, 204, 241);
    text-align: start;
    font-size:1.4rem;
}
.subTitle{
    color:white;
    font-size:1.4em;
}
.techStack{
    color:white;
    font-family: "Montserrat";
    font-size:1.4rem;
    list-style: none;
}
.techStack li{
    display:flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}