*,*::after,*::before {
  box-sizing: border-box;
}
:root {
    --main-bg-color:rgb(23,10,28);
}
body {
  background:var(--main-bg-color) ;
}
.App {
  text-align: center;
}
