@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

.bodyWrapper {
  background-color: #0F1527;
  width: 100%;
  height: 100vh;
  overflow:auto;
}

.addBtn {
  font-family: 'Helvetica', 'Arial', sans-serif;
  display: inline-block;
  font-size: 1em;
  padding: 1em 2em;
  background-color: inherit;
  color: #ff0081;
  border-radius: 4px;
  border: 2px solid #ff0081;
  cursor: pointer;
  position: relative;
  transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
  box-shadow: 0 2px 25px rgba(255, 0, 130, 0.5), 2px 2px 10px rgba(255, 0, 130, 0.5);
}

.addBtn::before,
.addBtn::after {
  position: absolute;
  content: '';
  display: block;
  width: 140%;
  height: 100%;
  left: -20%;
  z-index: -1000;
  transition: all ease-in-out 0.5s;
  background-repeat: no-repeat;
}

.addBtn::before {
  top: -75%;
  background-image: radial-gradient(circle, #ff0081 20%, transparent 20%), radial-gradient(circle, transparent 20%, #ff0081 20%, transparent 30%), radial-gradient(circle, #ff0081 20%, transparent 20%), radial-gradient(circle, #ff0081 20%, transparent 20%), radial-gradient(circle, transparent 10%, #ff0081 15%, transparent 20%), radial-gradient(circle, #ff0081 20%, transparent 20%), radial-gradient(circle, #ff0081 20%, transparent 20%), radial-gradient(circle, #ff0081 20%, transparent 20%), radial-gradient(circle, #ff0081 20%, transparent 20%);
  background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
}

.addBtn::after {
  bottom: -75%;
  background-image: radial-gradient(circle, #ff0081 20%, transparent 20%), radial-gradient(circle, #ff0081 20%, transparent 20%), radial-gradient(circle, transparent 10%, #ff0081 15%, transparent 20%), radial-gradient(circle, #ff0081 20%, transparent 20%), radial-gradient(circle, #ff0081 20%, transparent 20%), radial-gradient(circle, #ff0081 20%, transparent 20%), radial-gradient(circle, #ff0081 20%, transparent 20%);
  background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
}

.addBtn:active {
  transform: scale(0.9);
  background-color: #e60074;
  box-shadow: 0 2px 25px rgba(255, 0, 130, 0.2);
}

.animate::before {
  display: block;
  content: '';
  animation: 0.75s topBubbles ease-in-out forwards;
  animation-iteration-count: infinite;
}

.animate::after {
  display: block;
  content: '';
  animation: 0.75s bottomBubbles ease-in-out forwards;
  animation-iteration-count: infinite;
}

.inputElement {
  flex: 1 1 100%;
  display: inline-block;
  background-color: rgb(14, 2, 35);
  color: white;
  border: none;
  margin: 0.7rem;
  padding: 15px;
  border-radius: 15px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-inline: 1rem;
}

.headerText {
  color: rgb(240, 240, 240);
  font-family: "Raleway";
  font-size: 3rem;
}

.addTaskModal {
  background-color: rgb(15, 5, 41);
  width: 60%;
  margin-inline: auto;
  border-radius: 10px;
  opacity: 0.7;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.bg,
.button {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 100%;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 2px solid green;
  /* margin: 2rem; */
}

.bg {
  animation: pulse 1.2s ease infinite;
  background: rgba(15, 210, 236, 0.7);
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 99;
  border: none;
  background: rgba(0, 251, 255, 0.7);
  background-size: 18px;
  cursor: pointer;
  outline: none;
}

/* .button a {
  position: absolute;
  color: #FFF;
  font-size: 17px;
} */
.taskContainer {
  background-color: rgb(23, 9, 74);
  width: 75%;
  margin-inline: auto;
  border-radius: 15px;
  padding: 1rem;
  position: relative;
  border-top: 1px solid rgba(255, 49, 49, 0.5);
  border-right: 1px solid rgba(0, 255, 255, 0.5);
  border-bottom: 1px solid rgba(57, 255, 20, 0.5);
  border-left: 1px solid rgba(255, 255, 113, 0.5);
  overflow-y: auto;
  border:2px solid white;
  margin-bottom: 50px;
}

.addTaskModal {
  position: relative;
  color: #fff;
  background: transparent;
  border-top: 1px solid rgba(255, 49, 49, 0.5);
  border-right: 1px solid rgba(0, 255, 255, 0.5);
  border-bottom: 1px solid rgba(57, 255, 20, 0.5);
  border-left: 1px solid rgba(255, 255, 113, 0.5);
  padding: 1em;
}
.emptyDiv{
}
.emptyText{
  color:white;
  font-size: 2rem;
}
p {
  font-size: 0.95rem;
  /* text-align: center; */
}

span {
  position: absolute;
  border-radius: 100vmax;
}
.top {
  top: 0;
  left: 0;
  width: 0;
  height: 5px;
  background: linear-gradient(90deg,
      transparent 50%,
      rgba(255, 49, 49, 0.5),
      rgb(255, 49, 49));
}

.bottom {
  right: 0;
  bottom: 0;
  height: 5px;
  background: linear-gradient(90deg,
      rgb(57, 255, 20),
      rgba(57, 255, 20, 0.5),
      transparent 50%);
}

.right {
  top: 0;
  right: 0;
  width: 5px;
  height: 0;
  background: linear-gradient(180deg,
      transparent 30%,
      rgba(0, 255, 255, 0.5),
      rgb(0, 255, 255));
}

.left {
  left: 0;
  bottom: 0;
  width: 5px;
  height: 0;
  background: linear-gradient(180deg,
      rgb(255, 255, 113),
      rgba(255, 255, 113, 0.5),
      transparent 70%);
}

.top {
  animation: animateTop 3s ease-in-out infinite;
}

.bottom {
  animation: animateBottom 3s ease-in-out infinite;
}

.right {
  animation: animateRight 3s linear infinite;
}

.left {
  animation: animateLeft 3s ease-in-out infinite;
}

@keyframes animateTop {
  25% {
    width: 100%;
    opacity: 1;
  }

  30%,
  100% {
    opacity: 0;
  }
}

@keyframes animateBottom {

  0%,
  50% {
    opacity: 0;
    width: 0;
  }

  75% {
    opacity: 1;
    width: 100%;
  }

  76%,
  100% {
    opacity: 0;
  }
}

@keyframes animateRight {

  0%,
  25% {
    opacity: 0;
    height: 0;
  }

  50% {
    opacity: 1;
    height: 100%;
  }

  55%,
  100% {
    height: 100%;
    opacity: 0;
  }
}

@keyframes animateLeft {

  0%,
  75% {
    opacity: 0;
    bottom: 0;
    height: 0;
  }

  100% {
    opacity: 1;
    height: 100%;
  }
}


@keyframes pulse {
  0% {
    transform: scale(1, 1);
  }

  50% {
    opacity: 0.3;
  }

  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes topBubbles {
  0% {
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }

  50% {
    background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;
  }

  100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}

@keyframes bottomBubbles {
  0% {
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
  }

  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;
  }

  100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}

@media (max-width:1024px) {
  .addTaskModal {
    flex-direction: column;
  }
  .taskContainer{
    width: 90%;
  }
  .inputElement {
    width: 100%;
  }
}